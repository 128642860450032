.intro{
    height: calc(100vh-4rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.introContent {
    float: left;
    height: 500px;
    width: 690px;
    margin-top: 130px;
    margin-left: 80px;
    font-size: 2.7rem;
    font-weight: 700;
    display: inline-block;
}


.Right{
    float: right;
    margin-left: 15px;
    padding-left: 0px;
    height: 500px;
    width: 410px;
}


.bg{
    position: absolute;
    top: 3.5rem;
    right: 5rem;
    z-index: -1;
    object-fit: cover;
    height: 100vh;
    border-radius: 5px;

}



.hello {
    font-size: 1.75rem;
    font-weight: 100;
}
.introName {
    color: #0F969C;
    
}
  
  .btn {
    margin-top: 20px; /* Add some space above the button */
    padding: 10px 20px; /* Adjust padding as needed */
    background-color: #0C7075; /* Set button color */
    color: white; /* Set button text color */
    border: none; /* Remove border */
    border-radius: 4px; /* Add rounded corners */
    cursor: pointer; /* Indicate clickable behavior */
  }

 .para {
    margin-top: 10px;
    font-size: 18px;
    color: white;
 }
  
 @media screen and (max-width:840px) {
    .bg{
        right: -10vw
    }
    .introContent {
        font-size: 5vw;
    }

    .hello {
        font-size: 4.5vw;
    }
    .para {
        font-size: 3vw;
    }
 }


