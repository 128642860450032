.navbar{
    background-color: hsl(191, 68%, 6%);
    height: 5rem ;
    width: 100vw;
    
    margin: 0 auto;
    padding: 1rem 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top:0;
    z-index: 3;
    box-shadow: 0px 0px 3px 1px #072E33;
} 
.logo{
    margin-left: 5rem;
    height: 3rem;
    width: 4rem;
    border-radius: 5px;
}


.desktopMenueListItem{
    
    margin: 2vw;
    cursor: pointer;
}
.desktopMenueListIteml{
    
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 6rem;
    cursor: pointer;
}

.desktopMenueListIteml:hover {
    color: #0F969C;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid #0F969C;
}

.desktopMenueListItem:hover {
    color: #0F969C;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid #0F969C;
}
